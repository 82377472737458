import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"

const classNames = require('classname')
const styles = require('../styles/terms.module.css')

const PrivacyPage: React.FC = () => (
    <Layout>
        <SEO title="Privacy Policy" />
        <div className={styles.container}>
            <div className={classNames({ [styles.main]: true, [styles.mainRaised]: true })}>
                <div className={styles.blueBanner}>
                    <div className={styles.space150}></div>
                  <div className={styles.header}>
                    <h1 className={styles.statement}>Privacy Policy</h1>
                </div>  
                <div className={styles.space150}></div>
                </div>
                <div className={styles.mainPadding}>
                <p>Your privacy is critically important to us. At Park to Give we have a few fundamental principles:</p>
                <p>
                    We don’t ask you for personal information unless we truly need it. (We can’t stand services that ask you for things like your gender
                    or income level for no apparent reason.) We don’t share your personal information with anyone except to comply with the law, develop our products,
                    or protect our rights. We don’t store personal information on our servers unless required for the on-going operation of one of our services.
                    If you have questions about deleting or correcting your personal data please contact us at info@parktogive.com.
                </p>
                <p>
                    Park to Give operates the sites ParktoGive.com and Park.gives.
                    It is Park to Give’s policy to respect your privacy regarding any information we may collect while operating our websites.
                </p>
                <p><b>Website Visitors</b></p>
                <p>
                    Like most website operators, Park to Give collects non-personally-identifying information of the sort that web browsers
                    and servers typically make available, such as the browser type, language preference, referring site, GPS location and the date
                    and time of each visitor request. Park to Give's purpose in collecting non-personally identifying information is to better understand
                    how Park to Give's visitors use its websites. From time to time, Park to Give may release non-personally-identifying information in the aggregate,
                    e.g., by publishing a report on trends in the usage of its websites. Park to Give also collects potentially personally-identifying information
                    like Internet Protocol (IP) addresses for logged in users and visitors.
                    Park to Give only discloses IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below.
                </p>
                <p><b>Gathering of Personally-Identifying Information</b></p>
                <p>
                    Certain visitors to Park to Give websites choose to interact with Park to Give in ways that require Park to Give
                    to gather personally-identifying information. The amount and type of information that Park to Give gathers depends on the nature of the interaction.
                    For example, we ask visitors who sign up for a Park to Give account to provide a username, email address, name, contact phone number and address.
                    Those who engage in transactions with Park to Give – by paying for Park to Give services, for example – are asked to provide additional information,
                    including as necessary, the personal and financial information required to process those transactions and location data.
                    In each case, Park to Give collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor’s interaction
                    with Park to Give. Park to Give does not disclose personally-identifying information other than as described below.
                    And visitors can always refuse to supply personally-identifying information,
                    with the caveat that it may prevent them from engaging in certain website-related activities.
                </p>
                <p><b>Protection of Certain Personally-Identifying Information</b></p>
                <p>
                    Park to Give discloses potentially personally-identifying information only to those of its employees, contractors
                    and affiliated organizations that (i) need to know that information in order to process it on Park to Give 's behalf
                    or to provide services available at Park to Give websites, and (ii) that have agreed not to disclose it to others.
                     Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using Park to Give websites,
                     you consent to the transfer of such information to them. Park to Give will not rent or sell potentially personally-identifying information
                     to anyone other than to its employees, contractors and affiliated organizations, as described above.
                     Park to Give will only disclose potentially personally-identifying information in response to a subpoena, court order or other governmental requests,
                     or when Park to Give believes in good faith that disclosure is reasonably necessary to protect the property or rights of Park to Give,
                     third parties or the public at large. If you are a registered user of any Park to Give websites and have supplied your email address,
                     Park to Give may occasionally send you an email to tell you about new features, solicit your feedback,
                     or just keep you up-to-date with what is going on with Park to Give and our products. We primarily use our websites to communicate this type of information,
                     so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms),
                     we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Park to Give takes
                    all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying information.
                </p>
                <p><b>Cookies</b></p>
                <p>
                    A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website
                    each time the visitor returns. Park to Give uses cookies to help Park to Give identify and track visitors, their usage of Park to Give websites,
                    and their website access preferences. Park to Give visitors who do not wish to have cookies placed on their computers should set their browsers
                    to refuse cookies before using Park to Give websites, with the drawback that certain features may not function properly without the aid of cookies.
                </p>
                <p><b>Business Transfers</b></p>
                <p>
                    If Park to Give, or substantially all of its assets, are acquired, or in the unlikely event that Park to Give goes out of business
                    or enters bankruptcy, user information will be one of the assets that are transferred or acquired by a third party.
                    You acknowledge that such transfers may occur, and that any acquirer of Park to Give may continue to use your personal information as set forth in this policy.
                </p>
                <p><b>Privacy Policy Changes</b></p>
                <p>
                    Although most changes are likely to be minor, Park to Give may change its Privacy Policy from time to time, and in Park to Give's sole discretion.
                    Park to Give encourages visitors and users of Park to Give websites to frequently check this page for any changes to its Privacy Policy.
                    Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.
                </p>
                <p>
                    This privacy policy is adapted from https://automattic.com/privacy/ and licensed under the Creative Commons Sharealike License.
                    For details: https://creativecommons.org/licenses/by-sa/4.0/legalcode
                </p>
            </div>
            </div>
        </div>
    </Layout>
)

export default PrivacyPage